/*
 * @Author: chenliyan
 * @Date: 2022-08-23 16:18:13
 * @Description: 真快乐 form数据格式提交的相关接口请求
 */
import axios from "axios"
import { env } from "config/env.js"
import { requestInter, responseInter } from "./requestInter"

let baseUrl = env["ajaxMobilePath"]
let fetch = axios.create({
  timeout: 8000,
  baseURL: baseUrl ? baseUrl : "",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
  transformRequest: [
    (data) => {
      // 当是FormData数据-文件上传之类的时
      if (data instanceof FormData) {
        return data
      }
      let f = new FormData()
      f.append("body", JSON.stringify(data || {}))
      return f
    },
  ],
})

requestInter(fetch)
responseInter(fetch)
export default fetch
