/*
 * @Author: chenliyan
 * @Date: 2022-08-22 10:21:36
 * @Description: axios接口请求
 */
import axios from "axios"
import { requestInter, responseInter } from "./requestInter"

// dev通过proxy, build通过nginx映射实现
let baseUrl = window.location.origin
// let baseUrl =
//   process.env.VUE_APP_ENV === "prd"
//     ? `${location.protocol}//live.gome.com.cn`
//     : window.location.origin
let fetch = axios.create({
  timeout: 8000,
  baseURL: baseUrl || "",
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    //"Content-Type": "application/json; charset=UTF-8"
  },
  withCredentials: true,
})

requestInter(fetch)
responseInter(fetch)
export default fetch
