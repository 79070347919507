/* * @Author: chenliyan * @Date: 2022-08-24 16:15:00 * @Description:
带头部、左侧导航、面包屑的布局组件 */
<template>
  <div class="layout-page">
    <div class="layout-wrap" v-if="layoutVisile">
      <el-container>
        <side-Nav />
        <div class="layout-right">
          <headerTop></headerTop>
          <div
            :class="[
              'layout-content',
              isNoPermission ? 'not-allow' : '',
              isShowBg ? '' : 'hide-bg',
            ]"
          >
            <breadcrumb />
            <slot></slot>
          </div>
        </div>
      </el-container>
    </div>
    <div
      :class="[
        'iframe-content',
        isNoPermission ? 'not-allow' : '',
        isShowBg ? '' : 'hide-bg',
      ]"
      v-else
    >
      <slot></slot>
    </div>
    <!-- 弹窗-提示 -->
    <dialog-tip
      ref="dialogTip"
      :accountInfo="accountInfo"
      @success="reloadPage()"
    />
  </div>
</template>

<script>
import { fetchGetLiveAccountInfo } from "@/api/auth"
import { store } from "@/store/store.js"
import { setCookie, getCookie } from "@/utils/cookie"
import SideNav from "./sideNav/index.vue"
import HeaderTop from "./headerTop.vue"
import Breadcrumb from "./breadcrumb.vue"
import DialogTip from "./DialogTip.vue"
export default {
  name: "LayoutComp",
  components: { SideNav, HeaderTop, Breadcrumb, DialogTip },
  data() {
    return {
      // 当前登录平台：personal 个人  company 企业
      curPlatform:
        parent != window
          ? "company"
          : ["company", "personal"].includes(getCookie("zhubo_platform"))
          ? getCookie("zhubo_platform")
          : "personal",
      // 当前登录用户信息
      accountInfo: {},
      // 是否无权限
      isNoPermission: false,
      // 是否显示无权限提示背景页面
      isShowBg: true,
    }
  },
  computed: {
    layoutVisile() {
      return parent == window
    },
  },
  watch: {},
  created() {
    let domain = ".gome.com.cn"
    let type = /\.(\w+)\.com\.cn$/i.test(location.host) && RegExp.$1
    if (type == "gomeuat") {
      domain = ".gomeuat.com.cn"
    }
    // 设置cookie
    setCookie("zhubo_platform", this.curPlatform, 1, domain)

    // 获取登录用户主播账户信息、
    fetchGetLiveAccountInfo().then((res) => {
      if (res.success) {
        // 设置全局状态值
        store.setAccountInfo(res.data)
        this.accountInfo = res.data
        let tipInfo = this.checkAuth()
        if (tipInfo) {
          this.isNoPermission = true
          this.isShowBg = tipInfo.isShowBg
          if (tipInfo) this.$refs.dialogTip.show(tipInfo)
        }
      } else {
        this.$message.error(res.message || "获取主播信息失败")
      }
    })
  },
  mounted() {},
  methods: {
    // 判断权限
    checkAuth() {
      // 未开通直播权限：此时企业跟个人登录都判断
      if (this.accountInfo.permissionType !== 0) {
        // 非主页时
        if (window.location.href.indexOf("/centerHome.html") === -1) {
          return {
            title: "提示",
            content: "无权限访问",
          }
        }
        return null
      }
      // 不是企业登录，不需校验
      if (this.curPlatform !== "company") {
        return null
      }
      // 需升级为企业账户
      if (this.accountInfo.enableUpgradeEnterpriseAccount) {
        // 是在账户主页时提示升级账户
        if (window.location.href.indexOf("/centerHome.html") !== -1) {
          return {
            title: "升级为企业账号",
            content:
              "尊敬的企业用户好，您当前直播账号为个人账号，为了给您提供更优质的体验还请升级为企业账号",
            isUpgrade: true,
            // 是否显示页面背景
            isShowBg: true,
          }
        } else {
          // 其他页面提示无权限
          return {
            title: "提示",
            content: "无权限访问",
          }
        }
      }
      // 只有在统一身份认证平台拥有账号且符合产业公司和pop商家账号信息的用户才能进入直播平台PC端 (0-产业公司;3-pop店)
      if (![5, 6].includes(+this.accountInfo.type)) {
        return {
          title: "提示",
          content: "非产业公司和pop商家账号无权限访问",
        }
      }
      // 已绑定其他企业
      if (this.accountInfo.isOtherEnterprise) {
        // 是在账户主页时提示已绑定企业企业
        if (window.location.href.indexOf("/centerHome.html") !== -1) {
          return {
            title: "提示",
            content: `尊敬的企业用户您好，您当前直播账号已经绑定${
              this.accountInfo.otherEnterpriseName || "其他公司"
            }`,
            // 是否显示页面背景
            isShowBg: true,
          }
        } else {
          // 其他页面提示无权限
          return {
            title: "提示",
            content: "无权限访问",
          }
        }
      }
      // 子账号登录，访问主播列表提示无权限
      if (
        this.accountInfo.subAccountType == 1 &&
        window.location.href.indexOf("/anchorManage.html") !== -1
      ) {
        // 其他页面提示无权限
        return {
          title: "提示",
          content: "无权限访问",
        }
      }
      return null
    },
    // 升级后-刷新页面
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-wrap {
  min-width: 1200px;
}
.layout-right {
  width: 100%;
  overflow-x: hidden;
  .layout-content {
    padding: 20px 16px 0px;
    height: calc(100vh - 89px);
    overflow-y: auto;
  }
}
.iframe-content {
  padding: 0px;
  height: calc(100vh - 0px);
  min-width: 900px;
  overflow-y: none;
}
.not-allow {
  pointer-events: none;
  &.hide-bg {
    opacity: 0;
  }
}
</style>
