/* * @Author: chenliyan * @Date: 2022-09-05 10:13:38 * @Description:
升级为企业账号弹窗 */
<template>
  <el-dialog
    :visible="visible"
    :show-close="false"
    width="515px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    center
  >
    <div class="prompt-box">
      <img class="img" src="@/assets/images/live/upgrade.png" alt="" />
      <p class="title">
        {{ tipInfo.title }}
      </p>
      <p class="prompt">
        {{ tipInfo.content }}
      </p>
    </div>

    <el-button
      v-if="tipInfo.isUpgrade"
      @click="upgradeHandle"
      v-loading="btnLoading"
      slot="footer"
      type="primary"
      >立即升级</el-button
    >
  </el-dialog>
</template>

<script>
import { fetchUpgradeAccount } from "@/api/auth"
export default {
  name: "DialogUpgrade",
  components: {},
  props: {
    accountInfo: Object,
  },
  data() {
    return {
      visible: false,
      btnLoading: false,
      tipInfo: {
        title: "",
        content: "",
        isUpgrade: false,
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 显示
    show(info) {
      this.tipInfo = { ...info }
      this.btnLoading = false
      this.visible = true
    },
    close() {
      this.visible = false
    },
    // 升级账号
    upgradeHandle() {
      this.btnLoading = true
      fetchUpgradeAccount()
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message || "账号升级成功")
            this.$emit("success")
            this.close()
          } else {
            this.$message.error(res.message || "账号升级失败")
          }
          this.btnLoading = false
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.prompt-box {
  position: relative;
  padding: 30px 40px 20px;
  text-align: center;
  .img {
    width: 250px;
    position: absolute;
    left: 50%;
    top: -142px;
    transform: translateX(-50%);
  }
  .title {
    font-size: 22px;
    color: #303133;
    font-weight: 600;
    line-height: 30px;
  }
  .prompt {
    margin-top: 24px;
    font-size: 16px;
    color: #303133;
    line-height: 21px;
  }
}
</style>
