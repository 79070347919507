/*
 * @Author: chenliyan
 * @Date: 2022-08-22 10:24:16
 * @Description: 环境参数
 */
// 企业主播地址
let IAMZBDevUrl = `http://passport.gomeuat.com.cn/b/ZHIBO`
let IAMZBPrdUrl = `https://passport.gome.com.cn/b/ZHIBO`
// 个人中心地址
let IZBDevUrl = "http://zhubo.gomeuat.com.cn/centerHome.html"
let IZBPrdUrl = "https://g.gome.com.cn/centerHome.html"

let envConfig = {
  dev: {
    basePath: `${location.origin}`,
    ajaxMobilePath: `//mobile.gomeuat.com.cn`,
    loginPPUrl: `//passport.gomeuat.com.cn/login?url=${IAMZBDevUrl}&systemCode=ZHIBO`,
    loginIUrl: `http://login.gomeuat.com.cn/login?orginURI=${location.origin}/centerHome.html`,
    registerUrl: `http://reg.atguat.com.cn/register/index/person?orginURI=${location.origin}/centerHome.html`,
    // 企业-主播中心
    zbCenterUrl: IAMZBDevUrl,
    // 个人主播中心
    myCenterUrl: `${location.origin}/centerHome.html`,
  },
  uat: {
    basePath: `//zhubo.gomeuat.com.cn`,
    ajaxMobilePath: `//mobile.gomeuat.com.cn`,
    loginPPUrl: `//passport.gomeuat.com.cn/login?url=${IAMZBDevUrl}&systemCode=ZHIBO`,
    loginIUrl: `http://login.gomeuat.com.cn/login?orginURI=${IZBDevUrl}`,
    registerUrl: `http://reg.atguat.com.cn/register/index/person?orginURI=${IZBDevUrl}`,
    zbCenterUrl: IAMZBDevUrl,
    myCenterUrl: IZBDevUrl,
  },
  pre: {
    basePath: `//zhubo.gomeuat.com.cn`,
    ajaxMobilePath: `//mobile.gomeuat.com.cn`,
    loginPPUrl: `//passport.gomeuat.com.cn/login?url=${IAMZBDevUrl}&systemCode=ZHIBO`,
    loginIUrl: `http://login.gomeuat.com.cn/login?orginURI=${IZBDevUrl}`,
    registerUrl: `http://reg.atguat.com.cn/register/index/person?orginURI=${IZBDevUrl}`,
    zbCenterUrl: IAMZBDevUrl,
    myCenterUrl: IZBDevUrl,
  },
  prd: {
    basePath: `//g.gome.com.cn`,
    ajaxMobilePath: `//mobile.gome.com.cn`,
    loginPPUrl: `//passport.gome.com.cn/login?url=${IAMZBPrdUrl}&systemCode=ZHIBO`,
    loginIUrl: `https://login.gome.com.cn/login?orginURI=${IZBPrdUrl}`,
    registerUrl: `https://reg.gome.com.cn/register/index/person?orginURI=${IZBDevUrl}`,
    zbCenterUrl: IAMZBPrdUrl,
    myCenterUrl: IZBPrdUrl,
  },
}

let type = /\.(\w+)\.com\.cn$/i.test(location.host) && RegExp.$1
type = type == "gome" ? "prd" : type == "tslive" ? "pre" : "dev"

export const env = envConfig[type]
export const envs = envConfig
