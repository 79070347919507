<template>
  <div class="header-top">
    <div class="right">
      <div class="user">
        <svg-icon icon-class="user" /><span>{{ userInfo.name }}</span>
      </div>
      <div class="loginOut" @click="logout">
        <svg-icon icon-class="logout" /><span>退出</span>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchGomeLogout, fetchIAMLogout } from "../../api/auth"
import SvgIcon from "../SvgIcon/index.vue"
import { getCookie } from "@/utils/cookie"
import { store } from "@/store/store.js"
export default {
  data: function () {
    return {
      // 当前登录平台：personal 个人  company 企业
      curPlatform: getCookie("zhubo_platform"),
    }
  },
  computed: {
    // 当前登录主播账号信息
    userInfo() {
      return store.accountInfo || {}
    },
  },
  created() {},
  methods: {
    // 退出登录
    logout() {
      if (this.curPlatform == "company") {
        fetchIAMLogout().then((res) => {
          if (res.code == 0) {
            // 同时需退出国美账号
            fetchGomeLogout().then((res) => {
              if (res.result || res.isSuccess == "Y") {
                this.$message.success("退出成功")
                // 跳转到主页
                this.goIndex()
              } else {
                this.$message.error("退出失败")
              }
            })
          }
        })
      } else if (this.curPlatform == "personal") {
        // 国美账号退出登录
        fetchGomeLogout().then((res) => {
          if (res.result || res.isSuccess == "Y") {
            this.$message.success("退出成功")
            // 跳转到主页
            this.goIndex()
          } else {
            this.$message.error("退出失败")
          }
        })
      }
    },
    // 返回首页
    goIndex() {
      top.location.href = window.location.origin
    },
  },
  components: { SvgIcon },
}
</script>
<style lang="scss">
.header-top {
  background: #fff;
  padding: 0 25px;
  height: 68px;
  display: flex;

  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #edeff3;

  .right {
    display: flex;
    align-items: center;
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #8f9399;
      letter-spacing: 0;
      font-weight: 400;
    }
    .svg-icon {
      font-size: 14px;
      color: #8f9399;
      margin-right: 5px;
    }
    .user,
    .loginOut {
      display: flex;
      align-items: center;
      margin-right: 24px;
    }
    .loginOut {
      cursor: pointer;
    }
  }
}
</style>
