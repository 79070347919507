<template>
  <div class="comm-breadcrumb">
    <span class="item">您所在的位置：</span>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(item, index) in pagePath"
        :key="item.path + index"
        :class="item.url ? 'url' : ''"
        @click.native="goUrl(item.url)"
      >
        <span>{{ item.label }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import setMenuPath from "config/menuPath.js"
export default {
  name: "BreadCrumb",
  data: function () {
    return {
      // 页面路径
      pagePath: [],
    }
  },
  created() {
    this.getNodePath(setMenuPath(), location.pathname)
  },
  methods: {
    // 获取当前页面对应的菜单路径
    getNodePath(tree, curUrl) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].child && tree[index].child.length) {
          let childLoop = this.getNodePath(tree[index].child, curUrl)
          if (childLoop) {
            this.pagePath.unshift(tree[index])
            return true
          }
        }
        if (tree[index].url === curUrl) {
          this.pagePath.unshift(tree[index])
          return true
        }
      }
    },
    goUrl(url) {
      if (url) {
        if (/^(https?:|mailto:|tel:)/.test(url)) {
          window.location.href = url
        } else {
          window.location.href = window.location.origin + url
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.comm-breadcrumb {
  display: flex;
  padding: 0 0 20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  line-height: 12px;
  font-weight: 400;
  .el-breadcrumb__item {
    font-size: 12px;
    :deep(.el-breadcrumb__inner) {
      color: #a5a5a5;
    }
    &.url {
      cursor: pointer;
    }
    &:last-child {
      :deep(.el-breadcrumb__inner) {
        color: #666;
      }
    }
  }
}
</style>
