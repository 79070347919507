/* * @Author: chenliyan * @Date: 2022-09-01 10:19:54 * @Description:
上传图片-自定义预览形式-只支持单张 */
<template>
  <div class="upload-wrap">
    <el-upload
      :class="['upload-one', disabled ? 'disabled' : '']"
      ref="upload"
      action
      v-bind="$attrs"
      :show-file-list="false"
      :limit="1"
      :accept="accept"
      :on-exceed="handleExceed"
      :on-change="handleChange"
      :before-upload="beforeUpload"
      :http-request="on_upload"
      :disabled="disabled"
    >
      <slot
        ><el-button size="small" :class="[disabled ? 'is-disabled' : '']">{{
          value ? "更换图片" : "上传图片"
        }}</el-button></slot
      >
      <div slot="tip" class="el-upload__tip"><slot name="tip"></slot></div>
    </el-upload>
    <!-- 图片预览区域 -->
    <slot name="preview">
      <div
        :class="['preview-box', disabled ? 'disabled' : '']"
        :style="{ width: viewWidth, height: viewHeight }"
        v-loading="loading"
      >
        <template v-if="value">
          <img class="img" :src="value" alt="" />
          <span
            v-if="showRemove && !disabled"
            @click="removeHandle"
            class="remove-btn el-icon-error"
          ></span>
        </template>
        <div v-else class="empty-box" @click="pickerHandle">
          <i class="icon el-icon-plus"></i>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { fetchUploadImg } from "@/api/common"
export default {
  name: "UploadImgOne",
  components: {},
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    // 图片url: v-model形式
    value: {
      type: [String],
    },
    // 文件大小限制，单位KB
    limitSize: {
      type: Number,
      default: 0,
    },
    // 图片尺寸校验规则：width[Number | { min: '', max: '' }], height[Number | { min: '', max: '' }], ratio: [Number | { min: 4/3, max: 5/8 }], msg
    sizeRule: {
      type: Object,
      default() {
        return null
      },
    },
    // 文件类型限制
    accept: {
      type: String,
      default: ".png, .jpeg, .jpg, .webp",
    },
    // 是否显示移除图片
    showRemove: {
      type: Boolean,
      default: false,
    },
    // 上传图片的方法
    uploadFetch: {
      type: Function,
    },
    // 预览区域的宽
    viewWidth: {
      type: String,
      default: "242px",
    },
    // 预览区域的高
    viewHeight: {
      type: String,
      default: "167px",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleExceed() {
      this.$message.error("文件数超出限制!")
    },
    handleChange(file, fileList) {
      console.log("handleChange", file, fileList)
    },
    async beforeUpload(file) {
      // 校验文件类型
      let type = file.type.split("/").pop().toLowerCase()
      if (this.accept && this.accept.toLowerCase().indexOf(type) === -1) {
        this.$message.error("文件格式不符合！")
        // 发出选择文件失败响应
        this.$emit("pickError", file)
        // async 须return Promise.reject()
        return Promise.reject()
      }
      // 校验文件大小
      let sizeLimit = this.limitSize
      if (sizeLimit > 0 && file.size > sizeLimit * 1024) {
        if (sizeLimit >= 1024) {
          this.$message({
            message:
              "文件：" +
              file.name +
              "，超过" +
              Math.floor(sizeLimit / 1024) +
              "MB！",
            type: "error",
          })
        } else {
          this.$message({
            message: "文件：" + file.name + "，超过" + sizeLimit + "KB！",
            type: "error",
          })
        }
        // 发出选择文件失败响应
        this.$emit("pickError", file)
        return Promise.reject()
      }
      // 校验图片宽高
      if (this.sizeRule) {
        const _URL = window.URL || window.webkitURL
        await new Promise((resolve) => {
          const img = new Image()
          img.onload = function () {
            resolve(img)
          }
          img.src = _URL.createObjectURL(file)
        }).then((res) => {
          res.ratio = res.width / res.height
          if (
            !checkWH(this.sizeRule, res, "width") ||
            !checkWH(this.sizeRule, res, "height") ||
            !checkWH(this.sizeRule, res, "ratio")
          ) {
            this.$message.error("请上传正确尺寸的图片！")
            // 发出选择文件失败响应
            this.$emit("pickError", file)
            return Promise.reject(new Error("请上传正确尺寸的图片！"))
          }
        })
      }
      // 校验宽高函数
      function checkWH(rule, file, key) {
        // console.log(rule[key], file[key], key)
        if (rule[key]) {
          // 传的是：{max,min} 对象时
          if (rule[key] instanceof Object) {
            return (
              (rule[key].min || 0) <= file[key] &&
              file[key] <= (rule[key].max || 9999999)
            )
          } else {
            return file[key] == rule[key]
          }
        }
        return true
      }
      return true
    },
    // 移除
    removeHandle() {
      this.$emit("change", "")
    },
    // 自定义上传, oldFile 更换文件时传原文件
    on_upload(e) {
      this.loading = true
      // 外部上传时: file与cb两个参数，处理完外部需执行cb
      if (this.uploadFetch) {
        this.uploadFetch(e.file, (url) => {
          // 更新值
          url && this.$emit("change", url)
          // 清空上传组件文件
          this.$refs.upload.clearFiles()
          this.loading = false
        })
        return
      }
      var formData = new FormData()
      formData.append("image", e.file)
      fetchUploadImg(formData)
        .then((res) => {
          if (res.success && res.data) {
            // 更新值
            this.$emit("change", res.data.url)
          } else {
            this.$message.error(res.message)
          }
          // 清空上传组件文件
          this.$refs.upload.clearFiles()
          this.loading = false
        })
        .catch(() => {
          // 清空上传组件文件
          this.$refs.upload.clearFiles()
          this.loading = false
        })
    },
    // 手动触发选择图片
    pickerHandle() {
      try {
        this.$refs.upload.$refs["upload-inner"].handleClick()
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-box {
  position: relative;
  margin-top: 12px;
  &.disabled {
    .empty-box {
      cursor: not-allowed;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
  .remove-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    color: #a1a3a8;
    cursor: pointer;
  }
  .empty-box {
    width: 100%;
    height: 100%;
    @include center-cell();
    background: #fefefe;
    border: 1px dashed rgba(192, 204, 218, 1);
    border-radius: 6px;
    cursor: pointer;
    .icon {
      font-size: 21px;
      color: #8d939d;
    }
  }
}
</style>
