/*
 * @Author: chenliyan
 * @Date: 2022-08-25 14:09:22
 * @Description: 个人中心：左侧菜单路径
 */
import { getUrlParam } from "@/utils/index"
// 函数形式，避免污染
// permission权限：platform：personal 个人  company 企业， live: true / false(是否开通了直播权限), isMain：true / false(是否主账号)
export default function setMenuPath() {
  return [
    {
      deep: 1,
      label: "首页",
      path: "center",
      icon: "",
      svg: "home",
      url: "/centerHome.html",
    },
    {
      deep: 1,
      label: "主播列表",
      path: "anchor",
      icon: "el-icon-c-scale-to-original",
      svg: "",
      url: "/anchorManage.html",
      permission: {
        platform: "company",
        live: true,
        isMain: true,
      },
    },
    {
      deep: 1,
      label: "直播",
      path: "live",
      svg: "live",
      permission: {
        live: true,
      },
      child: [
        {
          deep: 2,
          label: "直播管理",
          path: "live/liveManage",
          url: "/liveManage.html",
          child: [
            {
              deep: 3,
              label: getUrlParam("isView")
                ? "直播详情"
                : getUrlParam("id")
                ? "编辑直播"
                : "创建直播",
              hidden: true,
              // 隐藏的页面菜单path设置为其父页面的
              path: "live/liveManage",
              url: "/liveEdit.html",
            },
            {
              deep: 3,
              label: "直播控制台",
              hidden: true,
              // 隐藏的页面菜单path设置为其父页面的
              path: "live/liveManage",
              url: "/liveConsole.html",
            },
            {
              deep: 3,
              label: "直播间",
              hidden: true,
              // 隐藏的页面菜单path设置为其父页面的
              path: "live/liveManage",
              url: "/liveRoom.html",
            },
          ],
        },
      ],
    },
  ]
}
