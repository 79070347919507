export function setCookie(name, value, Days = 1, domain = "") {
  // var Days = 1;
  var exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)

  document.cookie =
    name +
    "=" +
    escape(value) +
    ";expires=" +
    exp.toGMTString() +
    "; domain=" +
    domain
}

export function getCookie(name) {
  var arr = document.cookie.split("; ")
  for (var i = 0; i < arr.length; i++) {
    /* 将cookie名称和值拆分进行判断 */
    var arr2 = arr[i].split("=")
    if (arr2[0] == name) {
      return arr2[1]
    }
  }
  return ""
}

export function delCookie(name, domain = "") {
  var exp = new Date()
  let Days = -1
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  if (domain) {
    document.cookie =
      name + "=" + ";expires=" + exp.toGMTString() + `;path=/;domain=${domain}`
  } else {
    document.cookie = name + "=" + ";expires=" + exp.toGMTString() + `;path=/;`
  }
}
