/*
 * @Author: chenliyan
 * @Date: 2022-08-18 17:14:43
 * @Description: 小工具库
 */
const dayJs = require("dayjs")
/* 格式化时间 */
export function formatTime(value, format = "YYYY-MM-DD HH:mm") {
  if (typeof value === "string") {
    value = value.replace(/-/g, "/")
  }
  return value ? dayJs(value).format(format) : ""
}

// 根据key值获取url参数
export function getUrlParam(name, url) {
  url = url || window.location.href
  name = name.replace(/[[\]]/g, "\\$&")
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

// 数字千分位
export function formatNumber(number, def = "") {
  if (!number && number !== 0) {
    return def
  }
  return Number(number).toLocaleString("en-US") || def
}

// 数字转为万单位字符串
export function numToWan(num, len = 2, def = "") {
  if (!num && num !== 0) {
    return def
  }
  if (+num < 10000) {
    return formatNumber(num)
  }
  return formatNumber(parseFloat((+num / 10000).toFixed(len))) + "万"
}

// 导出，返回的文件流通过blob对象获取生成下载文件
export function dealExportByBlob(res, downloadName, type) {
  // 需要后端开放content-disposition，参考：response.setHeader("Access-Control-Expose-Headers", "Content-Disposition")
  // 否则获取不到content-disposition值，需传入downloadName
  const fileName =
    downloadName ||
    (res.headers["content-disposition"]
      ? decodeURI(res.headers["content-disposition"].split("filename=")[1])
      : "下载文件")
  const blob = new Blob([res.data], {
    type: type || res.data.type,
  })
  if (window.navigator.msSaveBlob) {
    // 没有此判断的话，ie11下的导出没有效果
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const downloadElement = document.createElement("a")
    const href = window.URL.createObjectURL(blob) // 创建下载的链接
    downloadElement.href = href
    downloadElement.download = fileName || "" // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  }
}

// 生成唯一标识uuid
export function generateUUID() {
  let d = new Date().getTime()
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function getElementTop(elem) {
  let elemTop = elem.offsetTop // 获得elem元素距相对定位的父元素的top
  elem = elem.offsetParent // 将elem换成起相对定位的父元素
  while (elem != null) {
    // 只要还有相对定位的父元素
    // 获得父元素 距他父元素的top值,累加到结果中
    elemTop += elem.offsetTop
    // 再次将elem换成他相对定位的父元素上;
    elem = elem.offsetParent
  }
  return elemTop
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 时间-毫秒数转为时间字符串
export function numToTimeStr(timer) {
  if (!timer && timer !== 0) {
    return ""
  }
  if (timer <= 0) {
    return "00:00:00"
  }
  let date = {}
  date.day = Math.floor(timer / 1000 / 60 / 60 / 24)
  date.hours = Math.floor((timer / 1000 / 60 / 60) % 24)
    .toString()
    .padStart(2, "0")
  date.minutes = Math.floor((timer / 1000 / 60) % 60)
    .toString()
    .padStart(2, "0")
  date.seconds = Math.floor((timer / 1000) % 60)
    .toString()
    .padStart(2, "0")
  if (date.day > 0) {
    return (
      date.day +
      "天" +
      date.hours +
      "时" +
      date.minutes +
      "分" +
      date.seconds +
      "秒"
    )
  } else {
    return date.hours + ":" + date.minutes + ":" + date.seconds
  }
}
