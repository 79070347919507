function openUrl(openUrl, titleName) {
  let appendTab = {
    type: "appendTab",
    url: openUrl,
    title: titleName,
  }
  if (parent != window) {
    // console.log("===appendTab=======", appendTab)
    window.parent.postMessage(JSON.stringify(appendTab), "*")
  } else {
    window.open(openUrl, "_blank")
  }
}

// 嵌入iframe之后 打开pdf 文件有问题 需要用父窗口打开
function openWin(openUrl) {
  let appendTab = {
    type: "appendWindow",
    url: openUrl,
  }
  if (parent != window) {
    // console.log("===appendTab=======", appendTab)
    window.parent.postMessage(JSON.stringify(appendTab), "*")
  } else {
    window.open(openUrl, "_blank")
  }
}

function closeUrl(isClose = true) {
  let deleteTab = {
    type: "deleteTab",
    url: location.href,
  }
  if (parent != window) {
    // console.log("===deleteTab=======", deleteTab)
    window.parent.postMessage(JSON.stringify(deleteTab), "*")
  } else {
    isClose && window.close()
  }
}

export { openUrl, closeUrl, openWin }
