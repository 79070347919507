/* * @Author: chenliyan * @Date: 2022-08-30 15:02:01 * @Description:
弹窗-复制URL(推流/引流地址) */
<template>
  <el-dialog
    :visible="visible"
    :title="info.title"
    :show-close="true"
    width="500px"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <p class="text" v-if="info.text">{{ info.text }}</p>
    <p class="push-url" v-if="info.url">{{ info.url }}</p>
    <p class="prompt" v-if="info.tip">
      <i class="el-icon-warning"></i> {{ info.tip }}
    </p>
    <el-button
      slot="footer"
      v-if="info.url"
      type="primary"
      data-clipboard-action="copy"
      class="addressUrlCopy"
      :data-clipboard-text="info.url"
      @click="copy"
      >复制链接</el-button
    >
  </el-dialog>
</template>

<script>
import Clipboard from "clipboard"
export default {
  name: "DialogAddress",
  components: {},
  data() {
    return {
      visible: false,
      info: {
        title: "",
        text: "",
        url: "",
        tip: "",
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 显示
    show(info) {
      this.info = { ...info }
      this.visible = true
    },
    close() {
      this.visible = false
    },
    copy() {
      var clipboard = new Clipboard(".addressUrlCopy")
      clipboard.on("success", () => {
        this.$message.success("复制成功")
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制")
        clipboard.destroy()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 16px;
}
.push-url {
  padding-top: 1em;
}
.prompt {
  padding-top: 12px;
  color: #a0a0a0;
  font-size: 13px;
  i {
    font-size: 16px;
    color: #eb8c25;
  }
}
</style>
