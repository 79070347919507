/*
 * @Author: chenliyan
 * @Date: 2022-08-22 11:01:35
 * @Description: axios接口请求自定义请求与响应体
 */
import { env } from "config/env.js"
import { Message } from "element-ui"
import { setCookie, getCookie } from "@/utils/cookie"

let domain = ".gome.com.cn"
let type = /\.(\w+)\.com\.cn$/i.test(location.host) && RegExp.$1
if (type == "gomeuat") {
  domain = ".gomeuat.com.cn"
}
let curPlatform =
  parent != window
    ? "company"
    : ["company", "personal"].includes(getCookie("zhubo_platform"))
    ? getCookie("zhubo_platform")
    : "personal"

// 设置cookie
setCookie("zhubo_platform", curPlatform, 1, domain)

// 请求处理
export const requestInter = (fetch) => {
  fetch.interceptors.request.use(
    (config) => {
      // if (process.env.VUE_APP_ENV === "prd") {
      //   config.url = config.url.replace(/\/apiLive/, ``)
      // }
      // 判断url, 路径中的平台路径转换为：个人personal 或 company路径前缀
      config.url = config.url.replace(/\/pc_platform\//, `/pc/${curPlatform}/`)
      // 全局请求加载样式：需在主页面添加<div class="global-loading"></div>
      let $loadingBox = document.querySelector(".global-loading")
      $loadingBox && ($loadingBox.style.display = "flex")
      return config
    },
    (error) => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )
}

// 处理响应体
export const responseInter = (fetch) => {
  fetch.interceptors.response.use(
    function (response) {
      // 隐藏全局加载中样式
      let $loadingBox = document.querySelector(".global-loading")
      $loadingBox && ($loadingBox.style.display = "none")
      const { data } = response
      if (
        typeof data === "string" ||
        typeof data === "number" ||
        Object.prototype.toString.call(data) === "[object Blob]"
      ) {
        return response
      }
      return data
    },
    (error) => {
      // 隐藏全局加载中样式
      let $loadingBox = document.querySelector(".global-loading")
      $loadingBox && ($loadingBox.style.display = "none")

      const errorMap = {
        400: "请求错误(400)",
        401: "未授权，请重新登录(401)",
        403: "拒绝访问(403)",
        404: "请求出错(404)",
        408: "请求超时(408)",
        500: "服务器错误(500)",
        501: "服务未实现(501)",
        502: "网络错误(502)",
        503: "服务不可用(503)",
        504: "网络超时(504)",
        505: "HTTP版本不受支持(505)",
      }
      let status = error.status || ""

      if (error.response && error.response.data) {
        status = parseInt(error.response.status)
        // 排除首页的跳转登录
        let extendCond =
          window.location.pathname == "/" ||
          window.location.pathname == "/index.html"
        // 未登录或无权限时跳转
        if (status == 401 && !extendCond) {
          switch (getCookie("zhubo_platform")) {
            // 个人
            case "personal":
              location.href = env["loginIUrl"]
              break
            // 企业
            case "company":
              top.location.href = env["loginPPUrl"]
              break
            default:
              top.location.href = window.location.origin
              break
          }
        }
        if (status != 401) {
          Message.error(
            error.response.data.message ||
              error.response.data.msg ||
              errorMap[+status] ||
              "接口异常"
          )
        }
      } else {
        Message.error(
          error.message || error.msg || errorMap[+status] || "接口异常"
        )
      }
      return Promise.reject(error.response)
    }
  )
}
