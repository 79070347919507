/*
 * @Author: chenliyan
 * @Date: 2022-09-06 15:54:54
 * @Description: 直播PC平台相关接口
 */
// 文档：http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11949
import fetch from "../utils/fetch"
// /apiLive 指向：http://live.atguat.com.cn，见proxy
// /pc_platform/ 会转换为 / pc / personal / 或 / pc / company /
let prefix = "/apiLive/pc_platform"

// 账号主页-直播面板数据获取
export function fetchUserLiveData(params) {
  // 接口文档：http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11949
  return fetch.post(`${prefix}/enterprise/live/account/getLiveData`, params)
}

// 获取主账号下子账号(主播)列表
export function fetchGetAccountList(params = {}) {
  // 文档 http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11951
  return fetch.post(
    `${prefix}/enterprise/live/account/getLiveAccountList`,
    params
  )
}

// 账号主页-前3条直播获取
export function fetchUserIndexLiveRooms(params) {
  // 接口文档：http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11950
  return fetch.post(`${prefix}/enterprise/live/account/getLiveRoomList`, params)
}
