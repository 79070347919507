var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-page"},[(_vm.layoutVisile)?_c('div',{staticClass:"layout-wrap"},[_c('el-container',[_c('side-Nav'),_c('div',{staticClass:"layout-right"},[_c('headerTop'),_c('div',{class:[
            'layout-content',
            _vm.isNoPermission ? 'not-allow' : '',
            _vm.isShowBg ? '' : 'hide-bg',
          ]},[_c('breadcrumb'),_vm._t("default")],2)],1)],1)],1):_c('div',{class:[
      'iframe-content',
      _vm.isNoPermission ? 'not-allow' : '',
      _vm.isShowBg ? '' : 'hide-bg',
    ]},[_vm._t("default")],2),_c('dialog-tip',{ref:"dialogTip",attrs:{"accountInfo":_vm.accountInfo},on:{"success":function($event){return _vm.reloadPage()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }