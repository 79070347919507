<template>
  <transition v-if="!item.hidden">
    <el-menu-item
      v-if="!isHasValidChild(item)"
      :index="item.path"
      @click="resolvePath(item)"
    >
      <template slot="title">
        <span class="group-title">
          <i :class="['icon', item.icon]" v-if="item.icon"></i>
          <svg-icon :icon-class="item.svg" v-else-if="item.svg" />
          <span class="title-name">{{ item.label }}</span>
        </span>
      </template>
    </el-menu-item>

    <el-submenu v-else ref="subMenu" :index="item.path" popper-append-to-body>
      <template slot="title">
        <span class="group-title">
          <i :class="['icon', item.icon]" v-if="item.icon"></i>
          <svg-icon :icon-class="item.svg" v-else-if="item.svg" />
          <span class="title-name">{{ item.label }}</span>
        </span>
      </template>
      <menu-item v-for="child in item.child" :key="child.path" :item="child" />
    </el-submenu>
  </transition>
</template>

<script>
import "@/icons"
export default {
  name: "MenuItem",
  components: {},
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 点击跳转
    resolvePath(item) {
      if (item.url) {
        if (/^(https?:|mailto:|tel:)/.test(item.url)) {
          window.location.href = item.url
        } else {
          window.location.href = window.location.origin + item.url
        }
      }
    },
    // 判断是否有显示的子菜单
    isHasValidChild(item) {
      if (!item.child || !item.child.length) {
        return false
      }
      const showingChildren = item.child.filter((child) => {
        return !child.hidden
      })
      return showingChildren.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
li.el-menu-item {
  display: flex;
  align-items: center;
}
.el-menu-item,
:deep(.el-submenu__title) {
  height: 40px;
  line-height: 40px;
  .group-title {
    display: flex;
    align-items: center;
    color: #fff;
    .icon,
    .svg-icon {
      color: #fff;
      opacity: 0.85;
      margin-right: 10px;
      font-size: 14px;
    }
    .title-name {
      opacity: 0.8;
    }
  }
  .el-icon-arrow-down {
    color: #fff;
    &::before {
      content: "\e790";
    }
  }
  &:hover {
    background: #c52fd6;
    .group-title {
      i,
      .svg-icon,
      .title-name {
        opacity: 1;
      }
    }
  }
  &.is-active {
    background-image: linear-gradient(-85deg, #ffdc01 1%, #ffb601 100%);
    .group-title {
      i,
      .svg-icon,
      .title-name {
        opacity: 1;
        color: #8808a9;
      }
    }
  }
}
.el-submenu {
  :deep(.el-menu) {
    background: transparent;
  }
}
</style>
