/*
 * @Author: chenliyan
 * @Date: 2022-08-23 15:32:35
 * @Description: 用户相关接口
 */
import fetch from "../utils/fetch"
import fetchMobile from "../utils/fetchMobile"

let type = /\.(\w+)\.com\.cn$/i.test(location.host) && RegExp.$1
// 是否是生产环境
let isProd = true
// 统一认证平台-接口地址
let IAMBaseUrl = "https://passport.gome.com.cn"
if (type == "gomeuat") {
  isProd = false
  IAMBaseUrl = "http://passport.gomeuat.com.cn"
}
const prefix = "/apiLive/pc_platform"

// 获取统一认证平台用户信息
export function fetchGetIAMUserinfo(params) {
  return fetch.get(`${IAMBaseUrl}/api/passport/account/getAccountInfo`, {
    params,
  })
}

// 统一认证平台 退出登录
export function fetchIAMLogout(params) {
  return fetch.post(`${IAMBaseUrl}/api/authLogout`, params)
}

// 获取真快乐账号信息: FormData: { body,"{}" } 形式传参
export function fetchGetGomeUserinfo(params) {
  return fetchMobile.post("/pc/profile/userProfileA.jsp", params)
}

// 真快乐账号 退出登录
export function fetchGomeLogout(params) {
  if (!isProd) {
    // url = "http://login.gomeuat.com.cn/logoutUrl.no" //PC页的退出
    // url = "http://mobile.gomeuat.com.cn/wap/profile/userLogout.jsp" //wap页的退出
    return fetchMobile.post("/wap/profile/userLogout.jsp", { pubkey: "" })
  }
  // 生产环境的退出地址
  let url = "https://login.gome.com.cn/logoutUrl.no"
  return fetch.get(url, { params })
}

// 获取直播账号信息
export function fetchGetLiveAccountInfo(params = {}) {
  // 文档：http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11948
  return fetch.post(
    `${prefix}/enterprise/live/account/getLiveAccountInfo`,
    params
  )
}

// 升级账户为企业账户
export function fetchUpgradeAccount(params = {}) {
  // 文档：http://nvwa.gome.inc/workspace/myWorkspace.do?projectId=529#11948
  return fetch.post(
    `${prefix}/enterprise/live/account/upgradeEnterpriseAccount`,
    params
  )
}
