/* * @Author: chenliyan * @Date: 2022-08-30 16:29:06 * @Description: 弹窗-二维码
*/
<template>
  <el-dialog
    :visible="visible"
    :title="title"
    :show-close="true"
    width="482px"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="code-wrap" v-loading="loading">
      <div class="code-item">
        <img class="image-code" :src="codeMap.appCode" alt="" />
        <p class="text">请使用真快乐App扫码</p>
      </div>
      <div class="code-item sun-code" v-if="type == 1">
        <img class="image-code" :src="codeMap.programCode" alt="" />
        <p class="text">请使用微信扫码</p>
      </div>
      <!-- <div class="code-item" v-if="type == 2">
        <img class="image-code" :src="codeMap.laigouCode" alt="" />
        <p class="text">请使用来购APP扫码</p>
      </div> -->
    </div>
    <el-button
      slot="footer"
      v-if="type == 1"
      type="primary"
      size="small"
      @click="downLoad()"
      >下载二维码</el-button
    >
  </el-dialog>
</template>

<script>
import { fetchGetLiveRoomQrcode } from "@/api/live"
export default {
  name: "DialogQrCode",
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      title: "",
      // type: 1 预览， 2 开播码
      type: "",
      codeMap: {
        // 真快乐二维码
        appCode: "",
        // 真快乐微信太阳码
        programCode: "",
        // 来购二维码
        laigouCode: "",
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // operateType 1: 真快乐， 2 来购
    show(id, operateType) {
      this.type = operateType
      this.title = operateType == 2 ? "开播码" : "预览"
      this.visible = true
      this.loading = true
      fetchGetLiveRoomQrcode({
        liveRoomId: id,
        operateType,
      }).then((res) => {
        if (res.success && res.data) {
          this.codeMap.appCode = res.data.appCodeUrl
          this.codeMap.programCode = res.data.miniProgramCodeUrl
          this.codeMap.laigouCode = res.data.pusherAppCodeUrl
          this.loading = false
        } else {
          this.$message.error(res.message || "获取二维码失败")
        }
      })
    },
    close() {
      this.visible = false
    },
    // 下载二维码
    downLoad() {
      if (this.codeMap.appCode) {
        this.downloadIamge(this.codeMap.appCode, "app扫码")
      }
      if (this.codeMap.programCode) {
        this.downloadIamge(this.codeMap.programCode, "微信扫码")
      }
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous")
      image.onload = function () {
        let canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        let context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        let url = canvas.toDataURL("image/png") //得到图片的base64编码数据
        let a = document.createElement("a") // 生成一个a元素
        let event = new MouseEvent("click") // 创建一个单击事件
        a.download = name || "photo" // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
  },
}
</script>

<style lang="scss" scoped>
.code-wrap {
  display: flex;
  min-height: 160px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  .code-item {
    margin-left: 35px;
    &:first-child {
      margin-left: 0;
    }
    .image-code {
      width: 168px;
    }
    .text {
      margin-top: 5px;
      line-height: 20px;
    }
  }
}
</style>
