/*
 * @Author: chenliyan
 * @Date: 2022-09-07 14:58:24
 * @Description: 响应式API做简单状态管理
 */
import { reactive } from "vue"

export const store = reactive({
  // 当前登录用户主播账号信息
  accountInfo: {},
  // 设置账号信息
  setAccountInfo(info = {}) {
    this.accountInfo = { ...info }
  },
})
