<template>
  <el-aside class="left-aside purple">
    <div class="topLogo">
      <div class="logo"></div>
      <p class="text">真快乐直播</p>
    </div>
    <el-menu :default-active="activeMenu" class="el-menu-vertical-demo">
      <menu-item v-for="route in menuList" :key="route.path" :item="route" />
    </el-menu>
  </el-aside>
</template>

<script>
import setMenuPath from "config/menuPath.js"
import MenuItem from "./menuItem.vue"
import { getCookie } from "@/utils/cookie"
import { store } from "@/store/store.js"
export default {
  name: "SideNav",
  components: { MenuItem },
  props: {},
  data() {
    return {
      menuList: [],
      activeMenu: "",
      // 当前登录平台：personal 个人  company 企业
      curPlatform: getCookie("zhubo_platform"),
    }
  },
  computed: {
    // 当前登录主播账号信息
    userInfo() {
      return store.accountInfo || {}
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        if (Object.keys(val).length) {
          this.getMenus()
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 处理菜单
    getMenus() {
      // 根据登录用户权限处理-需隐藏的路径设置属性 hidden:true 或直接移除
      this.menuList = setMenuPath().filter((item) => {
        // 需要权限控制
        if (item.permission) {
          for (let key in item.permission) {
            if (item.permission[key]) {
              if (
                key == "platform" &&
                item.permission[key] != this.curPlatform
              ) {
                return false
              }
              if (key == "live" && this.userInfo.permissionType !== 0) {
                return false
              }
              if (key == "isMain" && this.userInfo.subAccountType !== 0) {
                return false
              }
            }
          }
        }
        return true
      })
      // 获取当前页面对应的菜单路径
      this.getActiveMenu()
    },
    // 获取当前页面对应的菜单路径
    getActiveMenu() {
      let pathMap = (tree, curUrl) => {
        for (let index = 0; index < tree.length; index++) {
          if (tree[index].child && tree[index].child.length) {
            pathMap(tree[index].child, curUrl)
          }
          if (tree[index].url === curUrl) {
            this.activeMenu = tree[index].path
            return true
          }
        }
      }
      pathMap(this.menuList, location.pathname)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-aside {
  width: 160px !important;
  overflow-x: hidden;
  position: relative;
  z-index: 9;
  .topLogo {
    padding: 14px 14px 50px 14px;
    .logo {
      width: 86px;
      height: 50px;
      background: url(../../../assets/images/public/logo.png) 0 0 no-repeat;
      background-size: 100%;
    }
    .text {
      font-family: MicrosoftYaHeiSemibold;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 7px;
      font-weight: 600;
    }
  }
  &.purple {
    background-image: url(../../../assets/images/public/leftBg.png),
      linear-gradient(182deg, #b20fd3 0%, #640091 98%);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 100% 90%;
    .el-menu {
      border-right: 0;
      background: transparent;
    }
  }
}
</style>
